enum IModalActions {
  ToggleModal,
  OpenModal,
  CloseModal,
  UpdateModal
}

type IModalAction =
  | {
      type: IModalActions.CloseModal
    }
  | {
      type: Exclude<IModalActions, IModalActions.CloseModal>
      data: IModalState
    }

type IModalState = {
  isModalForceClose?: boolean
  isModalOpen?: boolean
  modalType?: ModalTypes | null
  modalData?: any
}

enum ModalTypes {
  VideoPopup = "VideoPopup",
  LoginModal = "LoginModal",
  ConnectModal = "ConnectModal",
  DownloadBrochure = "DownloadBrochure",
  DownloadBrochureLP = "DownloadBrochureLP",
  CallUsModal = "CallUsModal",
  emiModal = "emiModal",
  RegistrationForm = "RegistrationForm",
}

type LoginTypes = {
  withEmail: boolean
  withOTP: boolean
  forgetPass: boolean
}

export { IModalActions, ModalTypes }
export type { IModalAction, IModalState, LoginTypes }

