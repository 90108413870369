"use client";

import Link from "next/link";
import cn from "clsx";
import styles from "./button.module.scss";

const btnConfigurations = {
  transparent: "bg-transparent text-white border-white hover:text-[#ddd]",
  normal:
    "bg-yellow-400 text-primary-blue border-primary-blue hover:bg-yellow-500",
  blue: "",
};

export default function WarningButton({
  button,
  type = "transparent",
  iconDirection = "right",
  CN = "",
}: {
  button: {
    text: string;
    url: string;
    target: string;
    title?: string;
    enabled: boolean;
  };
  CN?: string;
  type?: "normal" | "transparent" | "blue";
  iconDirection?: "down" | "right";
}) {
  return (
    <Link
      href={button?.url || ""}
      target={button?.target}
      scroll={Boolean(button?.url)}
      prefetch={false}
    >
      <button
        className={cn(
          `cursor-pointer whitespace-nowrap text-primary-blue flex items-center gap-3 border-[1.5px] rounded-full ${btnConfigurations[type]} `,
          type == "blue" && styles.blue,
          styles.button,
          CN
        )}
      >
        <span className="uppercase font-raleway">{button?.text}</span>
        <SvgToUse type={type} iconDirection={iconDirection} />
      </button>
    </Link>
  );
}

export const SvgToUse = ({
  type,
  iconDirection,
}: {
  type: string;
  iconDirection: string;
}) => {
  if (iconDirection === "down") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 17 20"
        stroke={type !== "normal" ? "white" : "#1A325D"}
        fill="none"
      >
        <path
          d="M8.44141 1.5L8.44141 18.5"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M15.0889 11.7715L8.50023 18.3601"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M1.91138 11.7715L8.50001 18.3601"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      stroke={type !== "normal" ? "white" : "#1A325D"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Line_316"
        d="M1 7.91907L18 7.91907"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        id="Line_317"
        d="M11.271 1.27179L17.8596 7.86042"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        id="Line_318"
        d="M11.271 14.4493L17.8596 7.86065"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
